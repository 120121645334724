.hero-section {
    height: 100vh;
    background-image: url("../../assets/hero.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: inherit;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 0;
    }

    .container {
        height: inherit;
        .hero-content {
            color: white;
            display: flex;
            align-items: center;
            height: inherit;
            position: relative;
            z-index: 1;

            .hero-wrapper {
                .hero-title {
                    letter-spacing: 6px;
                    font: {
                        size: 50px;
                        weight: 800;
                    }
                }
                .hero-btn {
                    display: block;
                    margin-top: 20px;
                    padding: 10px 20px;
                    border: 2px solid white;
                    border-radius: 5px;
                    text-align: center;
                    max-width: 200px;
                    color: white;

                    font: {
                        size: 20px;
                        weight: 600;
                    }
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;

                    &:hover {
                        background-color: white;
                        color: black;
                    }
                }
            }
        }
    }
}
