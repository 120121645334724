@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap");
@import "./styles/variables";

body {
    margin: 0;
    font-family: "Dosis", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

html,
root,
body,
ul,
a {
    list-style: none;
    text-decoration: none;
}

.container {
    width: $container-width;
    margin: auto;
}

.color-main {
    color: $main-color;
}

.section {
    @extend .container;
    min-height: 100vh;
    padding-top: 74px;
}

.title {
    color: $main-color;
    margin-bottom: 15px;
    font-weight: 900;
    letter-spacing: 2.5px;
}
