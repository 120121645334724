@import "../../styles/variables.scss";

#contact {
    display: flex;

    .contact-form {
        width: 50%;
        display: flex;
        align-items: center;
        padding: 0 10px;

        form {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            input,
            textarea {
                margin-bottom: 15px;
                padding: 8px;
                border: 1px solid #ccc;
                border-radius: 5px;
                caret-color: $main-color;

                &:focus {
                    outline: none;
                    border-color: $main-color;
                }
                &[type="submit"] {
                    background-color: $main-color;
                    color: #fff;
                    cursor: pointer;
                    margin-left: auto;
                    padding: 5px 30px;
                    text-transform: capitalize;

                    &:hover {
                        background-color: $dark-main-color;
                    }
                }
            }

            textarea {
                resize: none;
            }
        }
    }

    .testimonial {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        overflow-x: clip;

        .slider {
            display: flex;
            transition: 0.5s ease-in-out;
        }
        .control {
            display: flex;
            justify-content: center;
            gap: 10px;

            .icon {
                font-size: 22px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #ccc;
                color: $main-color;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 767px) {
    #contact {
        flex-direction: column-reverse;
        gap: 20px;

        & > div {
            width: 100% !important;
        }
    }
}
