.footer {
    background-color: #000;
    color: #fff;
    padding: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;

    .accounts {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-bottom: 10px;
        font-size: larger;

        & > div {
            display: flex;
            align-items: center;
            gap: 5px;

            a {
                color: #fff;
                text-decoration: none;
                transition: 0.1s ease-in-out;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .copyright {
        .copyright-symbol {
            font-size: 15px;
        }
    }
}
