@import "../../styles/global";

#about {
    background-color: #fff;
    padding: 100px 0;

    .about-wrapper {
        height: 100%;
        @include flex-center;
        text-align: center;

        .about-content {
            width: 80%;

            .about-text {
                font-size: 18px;
            }
        }
    }
}
