@import "../../styles/variables";

.main-header {
    position: fixed;
    color: white;
    z-index: 1000;
    left: 0;
    right: 0;
    transition: background-color 0.1s ease-in-out, top 0.3s ease-in-out;

    &.hide {
        top: -100%;
    }
    &.show {
        top: 0;
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
    }

    .logo img {
        width: 140px;
        display: block;
    }

    .nav-links {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        li {
            font-size: 20px;

            a {
                color: white;
                padding: 25px 10px;
                transition: color 0.1s ease-in-out;
                font-weight: 700;

                &:hover,
                &.active {
                    color: $main-color;
                }
            }
        }
    }
}
