@import "../../styles/variables.scss";
.testimonial-card {
    background-color: #ccc;
    position: relative;
    padding: 50px 20px 20px;
    border-radius: 5px;

    .image {
        img {
            margin: 0 auto;
            display: block;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            border: 3px solid white;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -40px;
            object-fit: cover;
        }
    }
    .details {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-weight: 700;

        .job-title {
            font-size: 12px;
            margin-bottom: 5px;
            color: $main-color;
        }
        .name {
            font-size: 20px;
        }
    }

    .description {
        text-align: center;
    }

    .icon {
        position: absolute;
        top: 14px;
        font-size: 55px;
        right: 50px;
        opacity: 0.1;
    }
}

@media (max-width: 767px) {
    .testimonial-card {
        .icon {
            right: 20px;
        }
    }
}
