@import "../../styles/variables";

#services {
    background-color: #eee;
    padding-bottom: 20px;
    .service {
        display: flex;
        gap: 15px;

        &.reverse {
            flex-direction: row-reverse;
        }

        & > div {
            width: 50%;
        }

        .service-content {
            .service-description {
                margin: 30px 0 0 0;
            }

            ul {
                display: flex;
                gap: 20px;
                flex-direction: column;
                margin: 30px 0;

                li {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .icon {
                        font-size: 14px;
                        color: rgb(7, 240, 7);
                    }
                }
            }

            .stack-images {
                display: flex;
                gap: 20px;
                margin-bottom: 20px;

                img {
                    object-fit: contain;
                    width: 28px;
                    height: 28px;
                }
            }

            a {
                padding: 10px 40px;
                background: linear-gradient(
                    90deg,
                    $main-color 0%,
                    rgba(79, 197, 255, 1) 85%,
                    rgba(123, 241, 255, 1) 100%
                );
                display: inline-block;
                color: white;
                font-weight: 600;
                border-radius: 20px;
            }
        }

        .service-image {
            img {
                width: 100%;
            }
        }
    }
}

@media (max-width: 767px) {
    .service-image {
        display: none;
    }
    .service-content {
        flex-grow: 1;
        text-align: center;

        .details-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}
